import { graphql, StaticQuery } from 'gatsby'
import React from 'react'

import PreviewGrid from '../components/preview-grid'
import Seo from '../components/seo'
import { responsiveTitle1 } from '../components/typography.module.css'
import Layout from '../containers/layout'
import { mapEdgesToNodes } from '../lib/helpers'

const BlogPage = ({ data, errors }) => {
  const postNodes = data && data.posts && mapEdgesToNodes(data.posts)

  return (
    <Layout>
      <Seo title='Blog' />
      <h1 hidden>Leonidas's blog page</h1>
      <h2 className={responsiveTitle1}>Blog</h2>
      {postNodes && postNodes.length > 0 && <PreviewGrid type='blog' nodes={postNodes} />}
    </Layout>
  )
}

export default function BlogPageQuery () {
  return (
    <StaticQuery
      query={graphql`
      query BlogPageQuery {
        posts: allSanityPost(
          sort: { fields: [publishedAt], order: DESC }
          filter: { publishedAt: {ne: null}, slug: { current: { ne: null } } }
        ) {
          edges {
            node {
              id
              publishedAt
              mainImage {
                asset {
                  _id
                }
                alt
              }
              title
              _rawExcerpt
              slug {
                current
              }
            }
          }
        }
      }
    `}
      render={data => <BlogPage data={data} />}
    />
  )
}
